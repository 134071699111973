import classNames from "classnames";
import { Contacts } from "components/footer/Contacts";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import cross from "./cross.svg";
import logoMono from "./logo-mono.svg";

type Props = {
    onClick: () => void;
    className: string;
    children?: React.ReactNode;
};

export const ResponsiveNav: React.FC<Props> = ({
    onClick,
    className,
    children,
}) => {
    return (
        <div
            className={classNames(
                `${styles.responsive}`,
                className ? `${styles.responsive_active}` : ""
            )}
        >
            <div className={styles.responsiveWrap}>
                <div className="flex justify-between items-center pl-6">
                    <Link to="/" onClick={onClick}>
                        <img
                            src={logoMono}
                            alt="Nav logo"
                            width="70px"
                            height="18px"
                        />
                    </Link>
                    <button onClick={onClick}>
                        <img
                            src={cross}
                            alt="Cross"
                            width="56px"
                            height="56px"
                        />
                    </button>
                </div>
                {children}
                <ul className={styles.info}>
                    <Contacts className="contacts_unstyled" />
                </ul>
            </div>
        </div>
    );
};
