import { Navigate, useRoutes } from "react-router-dom";
import * as Main from "./pages/main";
import * as About from "./pages/about";
import * as Faq from "./pages/faq";
import * as Contacts from "./pages/contacts";
import * as News from "./pages/news";
import * as Post from "./pages/post";
import * as NotFound from "./pages/not-found";
import * as AccountDeletion from "./pages/account-deletion";

const MAIN_ROUTE = "/";
const ABOUT_ROUTE = "/about";
const FAQ_ROUTE = "/faq";
const NEWS_ROUTE = "/blog";
const POSTS_ROUTE = "/:category/:slug";
const CONTACTS_ROUTE = "/contacts";
const NOT_FOUND_ROUTE = "/not-found";
const ACCOUNT_DELETION_ROUTE = '/account-deletion';

export const Routes = () => {
    let routes = useRoutes([
        {
            path: MAIN_ROUTE,
            element: <Main.Page />,
        },
        {
            path: ABOUT_ROUTE,
            element: <About.Page />,
        },
        {
            path: FAQ_ROUTE,
            element: <Faq.Page />,
        },
        {
            path: NEWS_ROUTE,
            element: <News.Page />,
        },
        {
            path: POSTS_ROUTE,
            element: <Post.Page />,
        },
        {
            path: CONTACTS_ROUTE,
            element: <Contacts.Page />,
        },
        {
            path: NOT_FOUND_ROUTE,
            element: <NotFound.Page />,
        },
        {
            path: ACCOUNT_DELETION_ROUTE,
            element: <AccountDeletion.Page />,
        },
        {
            path: "*",
            element: <Navigate to={NOT_FOUND_ROUTE} replace />,
        },
    ]);

    return routes;
};
