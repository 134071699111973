// tslint:disable:max-line-length
export const Bobra = () => (
  <svg
    width="74"
    height="19"
    viewBox="0 0 74 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.687 10.2586C30.0282 9.50918 30.3135 9.09965 30.84 9.09965C30.9968 9.09761 31.1523 9.12689 31.2969 9.18569C31.4415 9.24448 31.5722 9.33153 31.6808 9.44144C31.7894 9.55135 31.8736 9.68177 31.9283 9.82463C31.9829 9.96749 32.0067 10.1197 31.9984 10.2719C31.9762 10.4694 31.9338 10.6642 31.872 10.8535L31.8714 10.8553L31.875 10.8514C31.4361 12.111 30.7374 13.2703 29.8216 14.2581C28.9057 15.2459 27.792 16.0415 26.5488 16.5961L26.5526 16.592L26.548 16.5941L26.4269 16.6453C24.0092 17.6012 21.4142 18.0603 18.8051 17.9937C14.7216 17.9937 13.2029 17.2088 11.9563 16.5646C11.0888 16.1163 10.3531 15.7361 8.97629 15.7361C7.29033 15.7361 6.18395 16.8083 5.54749 17.425C5.34558 17.6207 5.19096 17.7705 5.08013 17.8257C4.95329 17.914 4.80681 17.9719 4.65261 17.9947H4.64313C4.58211 17.9939 4.52296 17.9741 4.47441 17.9382C4.42587 17.9022 4.39051 17.852 4.37356 17.795C4.35634 17.5926 4.37958 17.3889 4.44201 17.1951C4.49606 16.963 4.54239 16.7405 4.581 16.5275C5.24778 12.8356 3.44823 11.909 2.68309 11.515C2.6048 11.4747 2.53733 11.4399 2.48445 11.4084C2.38619 11.3496 2.22273 11.271 2.02515 11.176C1.018 10.6915 -0.875598 9.78075 0.461608 8.90001C2.06114 7.84649 8.18758 7.69393 11.3551 8.5693C14.6868 9.48665 15.7851 10.6108 16.3895 11.5548C17.0489 12.6673 18.006 13.5854 19.159 14.2114C20.3121 14.8374 21.618 15.1479 22.9381 15.11C24.2582 15.0721 25.543 14.6872 26.6561 13.9961C27.7691 13.305 28.6688 12.3336 29.2595 11.1852L29.2953 11.1289C29.3652 10.9924 29.4386 10.8227 29.5149 10.6464C29.5708 10.5173 29.6282 10.3846 29.687 10.2586Z"
      fill="white"
    />
    <path
      d="M25.1742 10.3114C25.1742 11.5724 24.1686 12.5946 22.9281 12.5946C21.6877 12.5946 20.6821 11.5724 20.6821 10.3114C20.6821 9.05049 21.6877 8.02829 22.9281 8.02829C24.1686 8.02829 25.1742 9.05049 25.1742 10.3114Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.4404 10.8001C55.0254 11.0082 54.6593 11.3063 54.3675 11.6736L54.3725 8.7538C54.3749 8.65714 54.3587 8.56095 54.3248 8.47073C54.2909 8.3805 54.2401 8.29802 54.1751 8.22801C54.1101 8.15799 54.0323 8.10181 53.9461 8.06269C53.8599 8.02357 53.7671 8.00226 53.6729 8H53.402C53.402 8 53.391 13.4284 53.391 14.1125C53.3462 15.0107 53.6352 15.8929 54.1996 16.581C54.5064 16.9868 54.8985 17.3162 55.3468 17.5446C55.7951 17.7729 56.2878 17.8944 56.7883 17.8999C57.2426 17.9065 57.6929 17.8126 58.1086 17.6244C58.5243 17.4363 58.8955 17.1584 59.197 16.8097C59.8359 16.0947 60.1926 15.1613 60.1977 14.1913C60.2029 13.2213 59.8562 12.284 59.225 11.5618C58.9174 11.2083 58.5388 10.9274 58.1151 10.7385C57.6915 10.5497 57.2329 10.4574 56.7712 10.468C56.3094 10.4786 55.8553 10.5919 55.4404 10.8001ZM57.7046 16.6622C57.4045 16.8022 57.078 16.8729 56.7483 16.8692V16.8713C56.4206 16.876 56.0958 16.8071 55.7968 16.6694C55.4978 16.5318 55.2317 16.3287 55.0172 16.0744C54.5488 15.5411 54.2983 14.8434 54.3175 14.1258C54.307 13.7754 54.3639 13.4262 54.4849 13.0984C54.606 12.7705 54.7889 12.4704 55.0232 12.2151C55.2454 11.9691 55.5162 11.7744 55.8174 11.6439C56.1186 11.5134 56.4434 11.4501 56.7703 11.4583C57.1006 11.4523 57.4281 11.5219 57.7289 11.662C58.0297 11.8022 58.2964 12.0093 58.5094 12.2685C58.9633 12.7874 59.2109 13.4623 59.203 14.1596C59.2098 14.8637 58.9529 15.5436 58.4854 16.059C58.2713 16.3163 58.0048 16.5222 57.7046 16.6622Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.978 11.7424C37.2704 11.3758 37.6365 11.0785 38.0515 10.8708C38.4664 10.6632 38.9202 10.5502 39.3817 10.5396C39.8432 10.5289 40.3015 10.621 40.7251 10.8093C41.1487 10.9976 41.5275 11.2778 41.8355 11.6306C42.4668 12.353 42.8135 13.2904 42.8084 14.2606C42.8032 15.2308 42.4465 16.1643 41.8076 16.8795C41.5052 17.2275 41.1336 17.5048 40.7179 17.6929C40.3022 17.8809 39.8521 17.9753 39.3978 17.9697C38.8982 17.9635 38.4064 17.8421 37.9588 17.6143C37.5112 17.3865 37.1193 17.0583 36.8121 16.6539C36.2482 15.9651 35.9597 15.0827 36.0045 14.1843C36.0045 13.5002 36.0155 8.07287 36.0155 8.07287H36.2864C36.4766 8.07771 36.6571 8.15983 36.7883 8.30118C36.9195 8.44253 36.9906 8.63154 36.986 8.82668L36.978 11.7424ZM39.3588 16.939C39.6882 16.9425 40.0143 16.8717 40.3141 16.7317C40.6139 16.5917 40.8801 16.3859 41.0939 16.1288C41.5602 15.6159 41.817 14.939 41.8116 14.2376C41.822 13.5367 41.5747 12.8574 41.1189 12.3352C40.9059 12.0761 40.6393 11.8689 40.3384 11.7287C40.0376 11.5886 39.7101 11.519 39.3798 11.525C39.0545 11.516 38.7311 11.5778 38.4307 11.7061C38.1303 11.8344 37.8597 12.0264 37.6367 12.2696C37.4023 12.5249 37.2193 12.8252 37.0983 13.1532C36.9772 13.4813 36.9204 13.8306 36.9311 14.1812C36.9109 14.9026 37.1638 15.6039 37.6367 16.138C37.8503 16.393 38.1157 16.597 38.4142 16.7357C38.7127 16.8744 39.0371 16.9445 39.3648 16.9411L39.3588 16.939Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.918 10.466C48.3722 10.4602 48.8223 10.555 49.2377 10.7438C49.653 10.9327 50.0238 11.2112 50.3247 11.5603C50.9591 12.2787 51.3115 13.213 51.3137 14.1824C51.3159 15.1519 50.9678 16.0878 50.3367 16.8093C50.0284 17.153 49.6539 17.4273 49.237 17.615C48.8201 17.8026 48.3697 17.8995 47.9145 17.8995C47.4592 17.8995 47.0089 17.8026 46.5919 17.615C46.175 17.4273 45.8006 17.153 45.4922 16.8093C44.8572 16.0905 44.5054 15.155 44.5054 14.1848C44.5054 13.2146 44.8572 12.2791 45.4922 11.5603C45.7958 11.2093 46.1698 10.9298 46.5884 10.7409C47.0071 10.5521 47.4607 10.4583 47.918 10.466ZM47.918 16.881C48.2391 16.8838 48.557 16.8156 48.8502 16.6811C49.1434 16.5466 49.4049 16.349 49.6171 16.1016C50.0803 15.5922 50.3314 14.9173 50.3167 14.2207C50.3274 13.5143 50.0713 12.8311 49.6021 12.3141C49.3945 12.0618 49.1356 11.8589 48.8437 11.7198C48.5518 11.5807 48.2338 11.5086 47.912 11.5086C47.5901 11.5086 47.2721 11.5807 46.9802 11.7198C46.6883 11.8589 46.4294 12.0618 46.2218 12.3141C45.7554 12.8353 45.5018 13.5204 45.5132 14.2279C45.4975 14.9218 45.7427 15.5953 46.1978 16.1088C46.4123 16.3584 46.6774 16.5569 46.9746 16.6903C47.2717 16.8237 47.5937 16.8888 47.918 16.881Z"
      fill="white"
    />
    <path
      d="M64.7085 11.16L64.9414 10.7498C64.6774 10.5815 64.3762 10.4845 64.0658 10.4678C63.8142 10.4637 63.5683 10.5448 63.3662 10.6985C63.1249 10.8967 62.9196 11.137 62.7595 11.4082C62.7638 11.2109 62.6916 11.0199 62.5587 10.8772C62.4258 10.7345 62.2431 10.6518 62.0509 10.6472H61.751V17.6776H62.7595V15.2593C62.7337 14.4903 62.7839 13.7206 62.9094 12.962C62.9862 12.5893 63.1399 12.2379 63.3602 11.9313C63.4343 11.8161 63.5338 11.7205 63.6507 11.6523C63.7676 11.584 63.8985 11.545 64.0328 11.5385C64.168 11.5423 64.3018 11.5093 64.4205 11.4429C64.5392 11.3764 64.6385 11.2788 64.7085 11.16Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.9732 18H72.0026V16.637C71.7104 17.0227 71.3377 17.3364 70.9119 17.5551C70.4861 17.7737 70.018 17.8917 69.5419 17.9005C69.0907 17.9041 68.6441 17.8075 68.2326 17.6174C67.8212 17.4272 67.4546 17.1481 67.1581 16.799C66.5074 16.0888 66.1521 15.1462 66.1677 14.1715C66.1511 13.1982 66.5104 12.2577 67.1671 11.5553C67.4894 11.1977 67.8828 10.9154 68.3206 10.7273C68.7584 10.5393 69.2305 10.4499 69.7049 10.4653C70.1793 10.4806 70.6449 10.6004 71.0703 10.8164C71.4957 11.0324 71.8709 11.3396 72.1706 11.7173C72.4722 12.0781 72.6998 12.4978 72.8396 12.951C72.9794 13.4043 73.0286 13.8819 72.9841 14.3551L72.9732 18ZM69.6119 16.9088C69.9341 16.9102 70.2531 16.8421 70.5481 16.7089C70.843 16.5757 71.1074 16.3803 71.324 16.1355C71.5624 15.8792 71.7488 15.5768 71.8725 15.246C71.9962 14.9153 72.0547 14.5626 72.0446 14.2084C72.0645 13.4939 71.81 12.8 71.336 12.2762C71.1182 12.0265 70.8507 11.8277 70.5515 11.6932C70.2523 11.5587 69.9285 11.4917 69.6019 11.4968C69.2725 11.4933 68.9464 11.564 68.6466 11.704C68.3468 11.844 68.0805 12.0499 67.8668 12.307C67.4028 12.8247 67.1499 13.5052 67.1601 14.2084C67.1508 14.9136 67.4093 15.5948 67.8808 16.1078C68.0959 16.362 68.3624 16.5652 68.6616 16.7033C68.9609 16.8415 69.2858 16.9112 69.6139 16.9078L69.6119 16.9088Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.4404 10.8001C55.0254 11.0082 54.6593 11.3063 54.3675 11.6736L54.3725 8.7538C54.3749 8.65714 54.3587 8.56095 54.3248 8.47073C54.2909 8.3805 54.2401 8.29802 54.1751 8.22801C54.1101 8.15799 54.0323 8.10181 53.9461 8.06269C53.8599 8.02357 53.7671 8.00226 53.6729 8H53.402C53.402 8 53.391 13.4284 53.391 14.1125C53.3462 15.0107 53.6352 15.8929 54.1996 16.581C54.5064 16.9868 54.8985 17.3162 55.3468 17.5446C55.7951 17.7729 56.2878 17.8944 56.7883 17.8999C57.2426 17.9065 57.6929 17.8126 58.1086 17.6244C58.5243 17.4363 58.8955 17.1584 59.197 16.8097C59.8359 16.0947 60.1926 15.1613 60.1977 14.1913C60.2029 13.2213 59.8562 12.284 59.225 11.5618C58.9174 11.2083 58.5388 10.9274 58.1151 10.7385C57.6915 10.5497 57.2329 10.4574 56.7712 10.468C56.3094 10.4786 55.8553 10.5919 55.4404 10.8001ZM57.7046 16.6622C57.4045 16.8022 57.078 16.8729 56.7483 16.8692V16.8713C56.4206 16.876 56.0958 16.8071 55.7968 16.6694C55.4978 16.5318 55.2317 16.3287 55.0172 16.0744C54.5488 15.5411 54.2983 14.8434 54.3175 14.1258C54.307 13.7754 54.3639 13.4262 54.4849 13.0984C54.606 12.7705 54.7889 12.4704 55.0232 12.2151C55.2454 11.9691 55.5162 11.7744 55.8174 11.6439C56.1186 11.5134 56.4434 11.4501 56.7703 11.4583C57.1006 11.4523 57.4281 11.5219 57.7289 11.662C58.0297 11.8022 58.2964 12.0093 58.5094 12.2685C58.9633 12.7874 59.2109 13.4623 59.203 14.1596C59.2098 14.8637 58.9529 15.5436 58.4854 16.059C58.2713 16.3163 58.0048 16.5222 57.7046 16.6622Z"
      stroke="white"
      strokeWidth="0.25"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.978 11.7424C37.2704 11.3758 37.6365 11.0785 38.0515 10.8708C38.4664 10.6632 38.9202 10.5502 39.3817 10.5396C39.8432 10.5289 40.3015 10.621 40.7251 10.8093C41.1487 10.9976 41.5275 11.2778 41.8355 11.6306C42.4668 12.353 42.8135 13.2904 42.8084 14.2606C42.8032 15.2308 42.4465 16.1643 41.8076 16.8795C41.5052 17.2275 41.1336 17.5048 40.7179 17.6929C40.3022 17.8809 39.8521 17.9753 39.3978 17.9697C38.8982 17.9635 38.4064 17.8421 37.9588 17.6143C37.5112 17.3865 37.1193 17.0583 36.8121 16.6539C36.2482 15.9651 35.9597 15.0827 36.0045 14.1843C36.0045 13.5002 36.0155 8.07287 36.0155 8.07287H36.2864C36.4766 8.07771 36.6571 8.15983 36.7883 8.30118C36.9195 8.44253 36.9906 8.63154 36.986 8.82668L36.978 11.7424ZM39.3588 16.939C39.6882 16.9425 40.0143 16.8717 40.3141 16.7317C40.6139 16.5917 40.8801 16.3859 41.0939 16.1288C41.5602 15.6159 41.817 14.939 41.8116 14.2376C41.822 13.5367 41.5747 12.8574 41.1189 12.3352C40.9059 12.0761 40.6393 11.8689 40.3384 11.7287C40.0376 11.5886 39.7101 11.519 39.3798 11.525C39.0545 11.516 38.7311 11.5778 38.4307 11.7061C38.1303 11.8344 37.8597 12.0264 37.6367 12.2696C37.4023 12.5249 37.2193 12.8252 37.0983 13.1532C36.9772 13.4813 36.9204 13.8306 36.9311 14.1812C36.9109 14.9026 37.1638 15.6039 37.6367 16.138C37.8503 16.393 38.1157 16.597 38.4142 16.7357C38.7127 16.8744 39.0371 16.9445 39.3648 16.9411L39.3588 16.939Z"
      stroke="white"
      strokeWidth="0.25"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.918 10.466C48.3722 10.4602 48.8223 10.555 49.2377 10.7438C49.653 10.9327 50.0238 11.2112 50.3247 11.5603C50.9591 12.2787 51.3115 13.213 51.3137 14.1824C51.3159 15.1519 50.9678 16.0878 50.3367 16.8093C50.0284 17.153 49.6539 17.4273 49.237 17.615C48.8201 17.8026 48.3697 17.8995 47.9145 17.8995C47.4592 17.8995 47.0089 17.8026 46.5919 17.615C46.175 17.4273 45.8006 17.153 45.4922 16.8093C44.8572 16.0905 44.5054 15.155 44.5054 14.1848C44.5054 13.2146 44.8572 12.2791 45.4922 11.5603C45.7958 11.2093 46.1698 10.9298 46.5884 10.7409C47.0071 10.5521 47.4607 10.4583 47.918 10.466ZM47.918 16.881C48.2391 16.8838 48.557 16.8156 48.8502 16.6811C49.1434 16.5466 49.4049 16.349 49.6171 16.1016C50.0803 15.5922 50.3314 14.9173 50.3167 14.2207C50.3274 13.5143 50.0713 12.8311 49.6021 12.3141C49.3945 12.0618 49.1356 11.8589 48.8437 11.7198C48.5518 11.5807 48.2338 11.5086 47.912 11.5086C47.5901 11.5086 47.2721 11.5807 46.9802 11.7198C46.6883 11.8589 46.4294 12.0618 46.2218 12.3141C45.7554 12.8353 45.5018 13.5204 45.5132 14.2279C45.4975 14.9218 45.7427 15.5953 46.1978 16.1088C46.4123 16.3584 46.6774 16.5569 46.9746 16.6903C47.2717 16.8237 47.5937 16.8888 47.918 16.881Z"
      stroke="white"
      strokeWidth="0.25"
      strokeMiterlimit="10"
    />
    <path
      d="M64.7085 11.16L64.9414 10.7498C64.6774 10.5815 64.3762 10.4845 64.0658 10.4678C63.8142 10.4637 63.5683 10.5448 63.3662 10.6985C63.1249 10.8967 62.9196 11.137 62.7595 11.4082C62.7638 11.2109 62.6916 11.0199 62.5587 10.8772C62.4258 10.7345 62.2431 10.6518 62.0509 10.6472H61.751V17.6776H62.7595V15.2593C62.7337 14.4903 62.7839 13.7206 62.9094 12.962C62.9862 12.5893 63.1399 12.2379 63.3602 11.9313C63.4343 11.8161 63.5338 11.7205 63.6507 11.6523C63.7676 11.584 63.8985 11.545 64.0328 11.5385C64.168 11.5423 64.3018 11.5093 64.4205 11.4429C64.5392 11.3764 64.6385 11.2788 64.7085 11.16Z"
      stroke="white"
      strokeWidth="0.25"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.9732 18H72.0026V16.637C71.7104 17.0227 71.3377 17.3364 70.9119 17.5551C70.4861 17.7737 70.018 17.8917 69.5419 17.9005C69.0907 17.9041 68.6441 17.8075 68.2326 17.6174C67.8212 17.4272 67.4546 17.1481 67.1581 16.799C66.5074 16.0888 66.1521 15.1462 66.1677 14.1715C66.1511 13.1982 66.5104 12.2577 67.1671 11.5553C67.4894 11.1977 67.8828 10.9154 68.3206 10.7273C68.7584 10.5393 69.2305 10.4499 69.7049 10.4653C70.1793 10.4806 70.6449 10.6004 71.0703 10.8164C71.4957 11.0324 71.8709 11.3396 72.1706 11.7173C72.4722 12.0781 72.6998 12.4978 72.8396 12.951C72.9794 13.4043 73.0286 13.8819 72.9841 14.3551L72.9732 18ZM69.6119 16.9088C69.9341 16.9102 70.2531 16.8421 70.5481 16.7089C70.843 16.5757 71.1074 16.3803 71.324 16.1355C71.5624 15.8792 71.7488 15.5768 71.8725 15.246C71.9962 14.9153 72.0547 14.5626 72.0446 14.2084C72.0645 13.4939 71.81 12.8 71.336 12.2762C71.1182 12.0265 70.8507 11.8277 70.5515 11.6932C70.2523 11.5587 69.9285 11.4917 69.6019 11.4968C69.2725 11.4933 68.9464 11.564 68.6466 11.704C68.3468 11.844 68.0805 12.0499 67.8668 12.307C67.4028 12.8247 67.1499 13.5052 67.1601 14.2084C67.1508 14.9136 67.4093 15.5948 67.8808 16.1078C68.0959 16.362 68.3624 16.5652 68.6616 16.7033C68.9609 16.8415 69.2858 16.9112 69.6139 16.9078L69.6119 16.9088Z"
      stroke="white"
      strokeWidth="0.25"
      strokeMiterlimit="10"
    />
    <path
      d="M36 4.92308V0H37.6588C38.0423 0 38.3572 0.0657052 38.6033 0.197115C38.8494 0.326923 39.0315 0.505609 39.1498 0.733173C39.2681 0.960737 39.3272 1.21955 39.3272 1.50962C39.3272 1.79968 39.2681 2.05689 39.1498 2.28125C39.0315 2.50561 38.8502 2.68189 38.6057 2.8101C38.3612 2.9367 38.0487 3 37.6684 3H36.326V2.46154H37.6492C37.9113 2.46154 38.1222 2.42308 38.282 2.34615C38.4435 2.26923 38.5601 2.16026 38.632 2.01923C38.7055 1.8766 38.7423 1.70673 38.7423 1.50962C38.7423 1.3125 38.7055 1.14022 38.632 0.992788C38.5585 0.845353 38.4411 0.731571 38.2797 0.651442C38.1182 0.569712 37.9049 0.528846 37.6396 0.528846H36.5945V4.92308H36ZM38.3108 2.71154L39.519 4.92308H38.8286L37.6396 2.71154H38.3108Z"
      fill="white"
    />
    <path
      d="M42.5615 3.41346V1.23077H43.1272V4.92308H42.5615V4.29808H42.5231C42.4368 4.48558 42.3026 4.64503 42.1204 4.77644C41.9382 4.90625 41.7081 4.97115 41.4301 4.97115C41.1999 4.97115 40.9954 4.92067 40.8164 4.81971C40.6374 4.71715 40.4968 4.5633 40.3945 4.35817C40.2922 4.15144 40.2411 3.89103 40.2411 3.57692V1.23077H40.8068V3.53846C40.8068 3.80769 40.8819 4.02244 41.0321 4.18269C41.184 4.34295 41.3773 4.42308 41.6122 4.42308C41.7529 4.42308 41.8959 4.38702 42.0413 4.3149C42.1883 4.24279 42.3114 4.13221 42.4105 3.98317C42.5112 3.83413 42.5615 3.64423 42.5615 3.41346Z"
      fill="white"
    />
    <path
      d="M44.7291 2.70192V4.92308H44.1634V1.23077H44.7099V1.80769H44.7578C44.8441 1.62019 44.9752 1.46955 45.151 1.35577C45.3268 1.24038 45.5537 1.18269 45.8317 1.18269C46.081 1.18269 46.2992 1.23397 46.4862 1.33654C46.6731 1.4375 46.8186 1.59135 46.9224 1.79808C47.0263 2.00321 47.0782 2.26282 47.0782 2.57692V4.92308H46.5125V2.61538C46.5125 2.32532 46.4374 2.09936 46.2872 1.9375C46.137 1.77404 45.9308 1.69231 45.6687 1.69231C45.4882 1.69231 45.3268 1.73157 45.1845 1.8101C45.0439 1.88862 44.9328 2.00321 44.8513 2.15385C44.7698 2.30449 44.7291 2.48718 44.7291 2.70192Z"
      fill="white"
    />
    <path
      d="M50.7201 2.05769L50.2119 2.20192C50.1799 2.11699 50.1328 2.03445 50.0704 1.95433C50.0097 1.8726 49.9266 1.80529 49.8211 1.7524C49.7157 1.69952 49.5806 1.67308 49.416 1.67308C49.1907 1.67308 49.0029 1.72516 48.8527 1.82933C48.7041 1.93189 48.6298 2.0625 48.6298 2.22115C48.6298 2.36218 48.6809 2.47356 48.7832 2.55529C48.8855 2.63702 49.0453 2.70513 49.2626 2.75962L49.8092 2.89423C50.1384 2.97436 50.3837 3.09695 50.5451 3.26202C50.7065 3.42548 50.7872 3.63622 50.7872 3.89423C50.7872 4.10577 50.7264 4.29487 50.605 4.46154C50.4851 4.62821 50.3173 4.75962 50.1016 4.85577C49.8859 4.95192 49.635 5 49.3489 5C48.9734 5 48.6625 4.91827 48.4164 4.75481C48.1703 4.59135 48.0145 4.35256 47.949 4.03846L48.486 3.90385C48.5371 4.10256 48.6338 4.2516 48.776 4.35096C48.9198 4.45032 49.1076 4.5 49.3393 4.5C49.603 4.5 49.8124 4.44391 49.9674 4.33173C50.124 4.21795 50.2023 4.08173 50.2023 3.92308C50.2023 3.79487 50.1575 3.6875 50.068 3.60096C49.9786 3.51282 49.8411 3.44712 49.6557 3.40385L49.0421 3.25962C48.7049 3.17949 48.4572 3.05529 48.299 2.88702C48.1424 2.71715 48.0641 2.50481 48.0641 2.25C48.0641 2.04167 48.1224 1.85737 48.239 1.69712C48.3573 1.53686 48.5179 1.41106 48.7209 1.31971C48.9254 1.22837 49.1571 1.18269 49.416 1.18269C49.7804 1.18269 50.0664 1.26282 50.2742 1.42308C50.4835 1.58333 50.6322 1.79487 50.7201 2.05769Z"
      fill="white"
    />
    <path
      d="M55.0331 5C54.7007 5 54.409 4.92067 54.1581 4.76202C53.9088 4.60337 53.7138 4.38141 53.5732 4.09615C53.4342 3.8109 53.3647 3.47756 53.3647 3.09615C53.3647 2.71154 53.4342 2.3758 53.5732 2.08894C53.7138 1.80208 53.9088 1.57933 54.1581 1.42067C54.409 1.26202 54.7007 1.18269 55.0331 1.18269C55.3655 1.18269 55.6563 1.26202 55.9056 1.42067C56.1565 1.57933 56.3515 1.80208 56.4905 2.08894C56.6311 2.3758 56.7014 2.71154 56.7014 3.09615C56.7014 3.47756 56.6311 3.8109 56.4905 4.09615C56.3515 4.38141 56.1565 4.60337 55.9056 4.76202C55.6563 4.92067 55.3655 5 55.0331 5ZM55.0331 4.49038C55.2856 4.49038 55.4933 4.42548 55.6563 4.29567C55.8193 4.16587 55.94 3.99519 56.0183 3.78365C56.0966 3.57212 56.1357 3.34295 56.1357 3.09615C56.1357 2.84936 56.0966 2.61939 56.0183 2.40625C55.94 2.19311 55.8193 2.02083 55.6563 1.88942C55.4933 1.75801 55.2856 1.69231 55.0331 1.69231C54.7806 1.69231 54.5728 1.75801 54.4098 1.88942C54.2468 2.02083 54.1262 2.19311 54.0478 2.40625C53.9695 2.61939 53.9304 2.84936 53.9304 3.09615C53.9304 3.34295 53.9695 3.57212 54.0478 3.78365C54.1262 3.99519 54.2468 4.16587 54.4098 4.29567C54.5728 4.42548 54.7806 4.49038 55.0331 4.49038Z"
      fill="white"
    />
    <path
      d="M58.1307 2.70192V4.92308H57.565V1.23077H58.1115V1.80769H58.1595C58.2458 1.62019 58.3768 1.46955 58.5526 1.35577C58.7284 1.24038 58.9553 1.18269 59.2334 1.18269C59.4827 1.18269 59.7008 1.23397 59.8878 1.33654C60.0748 1.4375 60.2202 1.59135 60.3241 1.79808C60.428 2.00321 60.4799 2.26282 60.4799 2.57692V4.92308H59.9142V2.61538C59.9142 2.32532 59.8391 2.09936 59.6888 1.9375C59.5386 1.77404 59.3325 1.69231 59.0704 1.69231C58.8898 1.69231 58.7284 1.73157 58.5862 1.8101C58.4455 1.88862 58.3345 2.00321 58.253 2.15385C58.1715 2.30449 58.1307 2.48718 58.1307 2.70192Z"
      fill="white"
    />
    <path
      d="M65.0134 1.23077V1.71154H63.1053V1.23077H65.0134ZM63.6615 0.346154H64.2272V3.86538C64.2272 4.02564 64.2503 4.14583 64.2967 4.22596C64.3446 4.30449 64.4054 4.35737 64.4789 4.38462C64.554 4.41026 64.6331 4.42308 64.7162 4.42308C64.7785 4.42308 64.8296 4.41987 64.8696 4.41346C64.9096 4.40545 64.9415 4.39904 64.9655 4.39423L65.0805 4.90385C65.0422 4.91827 64.9887 4.93269 64.9199 4.94712C64.8512 4.96314 64.7641 4.97115 64.6587 4.97115C64.4988 4.97115 64.3422 4.9367 64.1888 4.86779C64.037 4.79888 63.9108 4.69391 63.8101 4.55288C63.711 4.41186 63.6615 4.23397 63.6615 4.01923V0.346154Z"
      fill="white"
    />
    <path
      d="M66.5092 2.70192V4.92308H65.9435V0H66.5092V1.80769H66.5572C66.6435 1.61699 66.7729 1.46554 66.9455 1.35337C67.1197 1.23958 67.3514 1.18269 67.6407 1.18269C67.8916 1.18269 68.1113 1.23317 68.2999 1.33413C68.4884 1.43349 68.6347 1.58654 68.7385 1.79327C68.844 1.9984 68.8967 2.25962 68.8967 2.57692V4.92308H68.331V2.61538C68.331 2.32212 68.2551 2.09535 68.1033 1.9351C67.9531 1.77324 67.7445 1.69231 67.4777 1.69231C67.2923 1.69231 67.1261 1.73157 66.9791 1.8101C66.8336 1.88862 66.7186 2.00321 66.6339 2.15385C66.5508 2.30449 66.5092 2.48718 66.5092 2.70192Z"
      fill="white"
    />
    <path
      d="M71.4754 5C71.1207 5 70.8146 4.92147 70.5573 4.76442C70.3017 4.60577 70.1043 4.38462 69.9653 4.10096C69.8278 3.81571 69.7591 3.48397 69.7591 3.10577C69.7591 2.72756 69.8278 2.39423 69.9653 2.10577C70.1043 1.8157 70.2977 1.58974 70.5454 1.42788C70.7947 1.26442 71.0855 1.18269 71.4179 1.18269C71.6097 1.18269 71.799 1.21474 71.986 1.27885C72.173 1.34295 72.3432 1.44712 72.4966 1.59135C72.65 1.73397 72.7723 1.92308 72.8634 2.15865C72.9545 2.39423 73 2.68429 73 3.02885V3.26923H70.1618V2.77885H72.4247C72.4247 2.57051 72.3831 2.38462 72.3 2.22115C72.2185 2.05769 72.1019 1.92869 71.9501 1.83413C71.7998 1.73958 71.6225 1.69231 71.4179 1.69231C71.1926 1.69231 70.9976 1.7484 70.833 1.86058C70.67 1.97115 70.5446 2.11538 70.4567 2.29327C70.3688 2.47115 70.3248 2.66186 70.3248 2.86538V3.19231C70.3248 3.47115 70.3728 3.70753 70.4687 3.90144C70.5661 4.09375 70.7012 4.24038 70.8738 4.34135C71.0464 4.4407 71.2469 4.49038 71.4754 4.49038C71.6241 4.49038 71.7583 4.46955 71.8782 4.42788C71.9996 4.38462 72.1043 4.32051 72.1922 4.23558C72.2801 4.14904 72.348 4.04167 72.3959 3.91346L72.9425 4.06731C72.8849 4.2532 72.7883 4.41667 72.6524 4.55769C72.5166 4.69711 72.3488 4.80609 72.149 4.88462C71.9493 4.96154 71.7247 5 71.4754 5Z"
      fill="white"
    />
  </svg>
);
