import { useIsMobile } from "hooks/use-is-mobile";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

const navList: Array<{ title: string; link: string }> = [
    {
        title: "Home",
        link: "",
    },
    {
        title: "About",
        link: "/about",
    },
    {
        title: "FAQ",
        link: "/faq",
    },
    {
        title: "Blog",
        link: "/blog",
    },
    {
        title: "Contacts",
        link: "/contacts",
    },
];

export const Nav: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
    const isMobile = useIsMobile();
    const activeLink =
        "pointer-events-none underline underline-offset-4 font-semibold";
    return (
        <nav className={styles.nav}>
            {navList.map(({ title, link }) => (
                <li key={title} className={styles.item}>
                    <NavLink
                        key={title}
                        to={`${link}`}
                        className={({ isActive }) =>
                            isActive ? activeLink : ""
                        }
                        onClick={isMobile ? onClick : undefined}
                        end
                    >
                        {title}
                    </NavLink>
                </li>
            ))}
        </nav>
    );
};
