import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "./icons";
import { Docs } from "./Docs";
import { Contacts } from "./Contacts";
import { useIsMobile } from "hooks/use-is-mobile";
import { useOpenState } from "@horat1us/react-hooks";
import styles from "./styles.module.scss";
import logo from "../header/logo.svg";
import bot from "./bot.svg";

export const Layout = () => {
    const isMobile = useIsMobile();
    const [isActive, setActive] = useOpenState();
    const location = useLocation();
    const year = new Date().getFullYear().toString();
    const ref = useRef<HTMLElement | null>(null);
    const toContacts = location.hash === "#contact";

    useEffect(() => {
        const elem = ref.current;
        if (!elem || !toContacts) {
            return;
        }
        const timeout = setTimeout(() => {
            elem.scrollIntoView({
                block: "end",
                inline: "nearest",
                behavior: "smooth",
            });
        }, 300);

        return () => clearTimeout(timeout);
    }, [ref, toContacts]);

    return (
        <footer id="contact" className={styles.footer} ref={ref}>
            <div className="container relative z-10 pt-[60px] mobile:pt-10 mobile:px-4">
                <h2 className="title text-center mb-[18px] text-white">
                    Get in touch
                </h2>
                <div className="flex mobile:flex-col mx-auto relative mb-7">
                    <Docs />
                    <div className="flex flex-col w-full pl-9 mobile:pl-0">
                        <p className="max-w-[314px] mobile:max-w-none text-center font-medium">
                            If you have any questions, please send an email to
                            us.
                        </p>
                        <Contacts />
                    </div>
                    {!isMobile && (
                        <img
                            src={bot}
                            alt="Bot"
                            width="220px"
                            height="228px"
                            className={styles.bot}
                        />
                    )}
                </div>
            </div>
            <div className={styles.below}>
                <div>
                    <a href="/#home">
                        <img src={logo} alt="Logo" width="66px" height="15px" />
                    </a>
                    <p
                        className="text-xs mt-2 opacity-50"
                        onDoubleClick={setActive}
                    >
                        {isActive
                            ? `Time: ${process.env.BUILD_TIME}. Build: ${process.env.TRAVIS_BUILD_NUMBER}.`
                            : `© ${year} All rights is reserved`}
                    </p>
                </div>
                <div className={styles.author}>
                    <a
                        href="https://wearesho.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Icon.Author />
                    </a>
                    <div className={styles.line} />
                    <a
                        href="https://wearesho.com/bobra-cs"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Icon.Bobra />
                    </a>
                </div>
            </div>
        </footer>
    );
};
