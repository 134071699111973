import React, { ReactElement } from "react";
import * as Configuration from "modules/api/use-config";
import * as Icon from "./icons";
import { formatPhoneNumber } from "utils/format-phone-number";
import "./contacts.scss";

type ContactsType = {
    icon: React.ReactNode;
    title: string;
    element: ReactElement | Array<ReactElement>;
};

type StylesType = "contacts_unstyled" | "contact-list";

export const Contacts: React.FC<{ className?: StylesType }> = ({
    className,
}) => {
    const config = Configuration.useConfig();

    const contactsData: Array<ContactsType> = [
        {
            icon:
                className === "contacts_unstyled" ? (
                    <Icon.EmailMono />
                ) : (
                    <Icon.Email />
                ),
            title: "Email",
            element: <a href="mailto:info@kobogo.ng">info@kobogo.ng</a>,
        },
    ];

    if (config?.phones) {
        contactsData.push({
            icon:
                className === "contacts_unstyled" ? (
                    <Icon.PhoneMono />
                ) : (
                    <Icon.Phone />
                ),
            title: "Phone",
            element: config.phones.support.map((item) => (
                <>
                    <a key={item} href={`tel:+${item}`}>
                        {formatPhoneNumber(item)}
                    </a>
                </>
            )),
        });
    }

    return (
        <ul className={className ?? "contacts"}>
            {contactsData.map(({ icon, title, element }) => (
                <li key={title}>
                    {icon}
                    <div className="mt-[2px] pl-2 w-full">
                        <h4 className="leading-none">{title}</h4>
                        <div>{element}</div>
                    </div>
                </li>
            ))}
        </ul>
    );
};
