import * as React from "react";

export type ConfigurationValue = {
    phones: {
        collection: string[];
        support: string[];
    };
};

export const useConfig = () => {
    const [config, setConfig] = React.useState<ConfigurationValue | undefined>(
        undefined
    );

    React.useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetch("https://api.kobogo.ng/v3/configuration", { signal })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setConfig(data);
            })
            .catch((e: Error) => {
                if (e.name === "AbortError") return;
                return Promise.reject(e);
            });

        return () => controller.abort();
    }, [setConfig]);

    return config;
};
