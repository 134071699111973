import React from "react";
import styles from "./styles.module.scss";

type ListItem = {
    name: string;
    path: string;
};

type DataItem = {
    name: string;
    list: ListItem[];
};

export const Docs = () => {
    const [data, setData] = React.useState<DataItem[] | undefined>(undefined);

    React.useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetch("https://static.kobogo.ng/public-documents.json", { signal })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data.list);
            })
            .catch((e: Error) => {
                if (e.name === "AbortError") return;
                return Promise.reject(e);
            });

        return () => controller.abort();
    }, [setData]);

    if (!data) {
        return null;
    }

    return (
        <ul className={styles.docs}>
            {data.map((item) =>
                item.list.map((i) => (
                    <li key={i.path} className="[&:not(:last-child)]:mb-1">
                        <a
                            href={`https://static.kobogo.ng/${i.path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:font-medium"
                        >
                            {i.name}
                        </a>
                    </li>
                ))
            )}
        </ul>
    );
};
