import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import * as Header from "./components/header";
import * as Footer from "./components/footer";
import { Preloader } from "./components/preloader";

function App() {
    return (
        <Suspense fallback={<Preloader />}>
            <BrowserRouter>
                <Header.Layout />
                <main className="flex-1">
                    <Routes />
                </main>
                <Footer.Layout />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
