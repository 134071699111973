import { Link } from "react-router-dom";
import { Nav } from "./Nav";
import { useIsMobile } from "hooks/use-is-mobile";
import { useOpenState } from "@horat1us/react-hooks";
import { MenuHamburger } from "./MenuHamburger";
import { ResponsiveNav } from "./ResponsiveNav";
import styles from "./styles.module.scss";
import logo from "./logo.svg";

export const Layout = () => {
    const isMobile = useIsMobile();
    const [isOpen, handleOpenChange] = useOpenState(false);

    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <Link to="/" className={styles.link}>
                        <img
                            src={logo}
                            alt="Logo"
                            width="111px"
                            height="28px"
                            className={styles.logo}
                        />
                    </Link>
                    {isMobile ? (
                        <MenuHamburger
                            isOpen={isOpen}
                            onClick={handleOpenChange}
                        />
                    ) : (
                        <Nav />
                    )}
                </div>
            </header>
            {isMobile && (
                <ResponsiveNav
                    onClick={handleOpenChange}
                    className={isOpen ? "responsive_active" : ""}
                >
                    <Nav onClick={handleOpenChange} />
                </ResponsiveNav>
            )}
        </>
    );
};
