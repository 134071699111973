// tslint:disable:max-line-length
export const Email = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_379_8822"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="42"
            height="42"
        >
            <rect width="42" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_379_8822)">
            <path
                d="M4.3 19.5006C3.8 19.5006 3.375 19.3256 3.025 18.9756C2.675 18.6256 2.5 18.2006 2.5 17.7006V7.50051C2.5 7.00051 2.675 6.65066 3.025 6.30066C3.375 5.95066 3.8 6.00051 4.3 6.00051H19.7C20.2 6.00051 20.5 6.00061 20.975 6.30056C21.325 6.65056 21.5 7.00056 21.5 7.50056V17.7006C21.5 18.2006 21.325 18.6256 20.975 18.9756C20.625 19.3256 20.2 19.5006 19.7 19.5006H4.3ZM12 14.0006L4 9.50061V17.7006C4 17.7839 4.02933 17.8549 4.088 17.9136C4.146 17.9716 4.21667 18.0006 4.3 18.0006H19.7C19.7833 18.0006 19.8543 17.9716 19.913 17.9136C19.971 17.8549 20 17.7839 20 17.7006V9.50061L12 14.0006ZM12 12.0006L20 7.50056H4L12 12.0006ZM4 9.50061V7.50056V17.7006C4 17.7839 4.02933 17.8549 4.088 17.9136C4.146 17.9716 4.21667 18.0006 4.3 18.0006H4V17.7006V9.50061Z"
                fill="url(#paint0_linear_379_8822)"
            />
            <path
                d="M4.3 19.5006C3.8 19.5006 3.375 19.3256 3.025 18.9756C2.675 18.6256 2.5 18.2006 2.5 17.7006V7.50051C2.5 7.00051 2.675 6.65066 3.025 6.30066C3.375 5.95066 3.8 6.00051 4.3 6.00051H19.7C20.2 6.00051 20.5 6.00061 20.975 6.30056C21.325 6.65056 21.5 7.00056 21.5 7.50056V17.7006C21.5 18.2006 21.325 18.6256 20.975 18.9756C20.625 19.3256 20.2 19.5006 19.7 19.5006H4.3ZM12 14.0006L4 9.50061V17.7006C4 17.7839 4.02933 17.8549 4.088 17.9136C4.146 17.9716 4.21667 18.0006 4.3 18.0006H19.7C19.7833 18.0006 19.8543 17.9716 19.913 17.9136C19.971 17.8549 20 17.7839 20 17.7006V9.50061L12 14.0006ZM12 12.0006L20 7.50056H4L12 12.0006ZM4 9.50061V7.50056V17.7006C4 17.7839 4.02933 17.8549 4.088 17.9136C4.146 17.9716 4.21667 18.0006 4.3 18.0006H4V17.7006V9.50061Z"
                fill="url(#paint1_radial_379_8822)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_379_8822"
                x1="12"
                y1="5.99805"
                x2="14.9303"
                y2="18.8315"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_379_8822"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(6.6402 5.99805) rotate(87.758) scale(16.4085 8.75843)"
            >
                <stop stopColor="#72D9FF" />
                <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
