import * as React from "react";
import { Config } from "config";

export const Page = React.lazy(() =>
    Promise.all([
        import(/* webpackChunkName: "not-found" */ "./Layout"),
        new Promise((resolve) =>
            setTimeout(resolve, Config.pageLoadDelayTimeoutMs)
        ),
    ]).then(([module]) => module)
);
